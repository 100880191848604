// extracted by mini-css-extract-plugin
export var caseStudyContainer = "CaseStudiesModule-module--caseStudyContainer--e9821";
export var caseStudySubTitle = "CaseStudiesModule-module--caseStudySubTitle--48828";
export var caseStudyTitle = "CaseStudiesModule-module--caseStudyTitle--516d1";
export var content = "CaseStudiesModule-module--content--d8045";
export var image = "CaseStudiesModule-module--image--c8b43";
export var line = "CaseStudiesModule-module--line--a2102";
export var listItem = "CaseStudiesModule-module--listItem--743df";
export var myAnimation = "CaseStudiesModule-module--my-animation--98c2f";
export var paragraph = "CaseStudiesModule-module--paragraph--026d0";
export var section = "CaseStudiesModule-module--section--abdad";
export var textStyleLargeTitle = "CaseStudiesModule-module--text-style-large-title--b3e7a";
export var textStyleLargestTitle = "CaseStudiesModule-module--text-style-largest-title--c4b85";
export var textStyleMediumTitle = "CaseStudiesModule-module--text-style-medium-title--72794";
export var textStyleSmallTitle = "CaseStudiesModule-module--text-style-small-title--8e790";
export var title = "CaseStudiesModule-module--title--99311";
export var topic = "CaseStudiesModule-module--topic--188b4";