import React from 'react';

import AnchorLink from 'react-anchor-link-smooth-scroll';
import { clsx } from '../../utils/utils';
import * as styles from './CTABannerModule.module.scss';

export type CTABannerModuleProps = {
  title: string;
  buttonText: string;
  to: string;
};

const CTABannerModule = ({ title, buttonText, to }: CTABannerModuleProps): React.ReactElement => {
  return (
    <section className={clsx(styles.section)}>
      <div className={styles.contentContainer}>
        <h2 className={styles.title}>{title}</h2>
        <AnchorLink href={to} offset="100">
          <button className={styles.button}>{buttonText}</button>
        </AnchorLink>
      </div>
    </section>
  );
};

export default CTABannerModule;
