import React from 'react';
import { clsx } from '../../utils/utils';

import * as styles from './CaseStudiesModule.module.scss';

export type CaseStudiesModuleProps = {
  title: string;
  caseStudies: Array<{
    title: string;
    description: string;
    solution: string;
    resultsList: Array<string>;
    image: {
      asset: {
        url: string;
      };
    };
  }>;
};

const CaseStudiesModule = ({ title, caseStudies }: CaseStudiesModuleProps): React.ReactElement => {
  return (
    <section className={clsx(styles.section)}>
      <h2 className={styles.title}>{title}</h2>
      {caseStudies.map((caseStudy, i) => (
        <div key={i} className={styles.caseStudyContainer}>
          <div className={styles.content}>
            <span className={styles.topic}>Case Study:</span>
            <h4 className={styles.caseStudyTitle}>{caseStudy.title}</h4>
            <p className={styles.paragraph}>{caseStudy.description}</p>
            <div className={styles.line}></div>
            <span className={styles.caseStudySubTitle}>Solution</span>
            <p className={styles.paragraph}>{caseStudy.solution}</p>

            <span className={styles.caseStudySubTitle}>Results</span>
            {caseStudy.resultsList.map((resultItem, i) => (
              <span className={styles.listItem} key={i}>
                {resultItem}
              </span>
            ))}
          </div>
          <div className={styles.image}>
            <img src={caseStudy.image.asset.url} alt="" />
          </div>
        </div>
      ))}
    </section>
  );
};

export default CaseStudiesModule;
