import React from 'react';

import { replaceNewLinesWithBr } from '../../utils/sanity';
import { clsx } from '../../utils/utils';
import * as styles from './NumbersListModule.module.scss';

export type NumbersListModuleProps = {
  title: string;
  text: string;
  numbers: Array<{
    supertitle: string;
    number: string;
    subtitle: string;
  }>;
};

const NumbersListModule = ({
  title,
  text,
  numbers,
}: NumbersListModuleProps): React.ReactElement => {
  return (
    <section className={clsx(styles.section)}>
      <div className={styles.contentContainer}>
        <div className={styles.numbersContainer}>
          {numbers.map((number, i) => (
            <div key={i} className={styles.numberContainer}>
              <span className={styles.numberSupertitle}>{number.supertitle}</span>
              <div className={styles.divider}></div>
              <span className={styles.number}>{number.number}</span>
              <span className={styles.numberSubtitle}>{number.subtitle}</span>
            </div>
          ))}
        </div>
        <div className={styles.textContainer}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>
        </div>
      </div>
    </section>
  );
};

export default NumbersListModule;
