import { graphql } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../../components/Footer';
import PageSEO from '../../components/PageSEO';
import TopBanner from '../../components/ui/TopBanner';
import { CALL_BOOKED_URL, FILE_QUERY_PARAM, NOAUTODOWNLOAD_QUERY_PARAM } from '../../constants';
import { LocalizedSEO } from '../../fragments';
import hookPointLogo from '../../images/hookPoint.svg';
import hookPointLogoBlack from '../../images/hookPointBlack.svg';
import {
  useActivateGoogleOptimize,
  useStoreReferrerOnLoad,
  useStoreScheduleOnceCalendarOnLoad,
  useStoreUTMParamsOnLoad,
} from '../../utils/hooks';
import {
  clsx,
  generateUniqueId,
  getLocalStorageMemoizedValue,
  removeParamsFromQueryParams,
  slugify,
  withDataLayer,
  wrapCurlyBracedInNoBreak,
  wrapSquareBracketedWithEm,
} from '../../utils/utils';

import CTABannerModule, { CTABannerModuleProps } from '../../components/modules/CTABannerModule';
import CaseStudiesModule, {
  CaseStudiesModuleProps,
} from '../../components/modules/CaseStudiesModule';
import NumbersListModule, {
  NumbersListModuleProps,
} from '../../components/modules/NumbersListModule';
import VideoSection from '../../components/modules/VideoSection';
import { useGlobalState } from '../../state/globalStateContext';
import { replaceNewLinesWithBr } from '../../utils/sanity';
import * as styles from './schedule-call.module.scss';

export const query = graphql`
  {
    sanityLpViralScheduleCallPage {
      videoSection {
        title
        videoUrl
      }
      title
      text
      caseStudySection {
        title
        caseStudies {
          title
          description
          solution
          resultsList
          image {
            asset {
              url
            }
          }
        }
      }
      numbersSection {
        title
        text
        numbers {
          supertitle
          number
          subtitle
        }
      }
      ctaSection {
        title
        buttonText
      }
      seo {
        ...SEO
      }
    }
    sanityGetFileSettings {
      downloadableFiles {
        fileParameterSlug {
          current
        }
        fileUrl
      }
    }
  }
`;

interface ScheduleCallPageProps {
  data: {
    sanityLpViralScheduleCallPage: {
      videoSection: {
        title: string;
        videoUrl: string;
      };
      title: string;
      text: string;
      caseStudySection: CaseStudiesModuleProps;
      numbersSection: NumbersListModuleProps;
      ctaSection: CTABannerModuleProps;
      seo: LocalizedSEO;
    };
    sanityGetFileSettings: {
      downloadableFiles: Array<{
        fileParameterSlug: {
          current: string;
        };
        fileUrl: string;
      }>;
    };
  };
}

const ScheduleCallPage = ({ data }: ScheduleCallPageProps): React.ReactElement => {
  const { theme, scheduleOnceCalendar } = useGlobalState();
  const [downloadFileUrl, setDownloadFileUrl] = useState<null | string>(null);
  const [autoDownload, setAutoDownload] = useState<boolean>(true);
  const socalendarScheduleEventTriggered = useRef(false);
  const { videoSection, title, text, caseStudySection, numbersSection, ctaSection, seo } =
    data.sanityLpViralScheduleCallPage;
  const isHidden = useActivateGoogleOptimize() || theme === null;
  useStoreUTMParamsOnLoad();
  useStoreReferrerOnLoad();
  useStoreScheduleOnceCalendarOnLoad();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const fileParamValue = urlSearchParams.get(FILE_QUERY_PARAM);
    const noAutoDownloadParamValue = urlSearchParams.get(NOAUTODOWNLOAD_QUERY_PARAM) !== null;

    if (fileParamValue || noAutoDownloadParamValue) {
      // Remove file and noautodownload query params
      removeParamsFromQueryParams([FILE_QUERY_PARAM, NOAUTODOWNLOAD_QUERY_PARAM]);

      if (fileParamValue) {
        const downloadableFile = data.sanityGetFileSettings.downloadableFiles.find(
          ({ fileParameterSlug }) => slugify(fileParameterSlug.current) === slugify(fileParamValue),
        );
        if (downloadableFile) {
          setDownloadFileUrl(downloadableFile.fileUrl);
          if (noAutoDownloadParamValue) {
            setAutoDownload(false);
          } else {
            window.open(downloadableFile.fileUrl);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (scheduleOnceCalendar) {
      window.addEventListener(
        'message',
        event => {
          if (
            event.origin === 'https://go.oncehub.com' &&
            event.data?.bookingData &&
            event.data?.bookingData?.errorStatus === 0 &&
            !socalendarScheduleEventTriggered.current
          ) {
            withDataLayer(dataLayer => {
              dataLayer.push({
                event: 'socalendar-schedule',
                calendar: scheduleOnceCalendar,
                uniqueId: getLocalStorageMemoizedValue(
                  'randomly_generated_unique_id',
                  generateUniqueId,
                ),
              });
            });
            socalendarScheduleEventTriggered.current = true;
            window.location.href = CALL_BOOKED_URL;
          }
        },
        false,
      );
    }
  }, [scheduleOnceCalendar]);

  return (
    <main
      style={isHidden ? { opacity: 0 } : undefined}
      className={styles.main}
      data-theme={theme === 'light' ? 'light' : ''}
    >
      <PageSEO defaultTitle="Schedule a Call" pageSEO={seo} />
      <Helmet>
        {scheduleOnceCalendar && (
          // ScheduleOnce embed
          <script type="text/javascript" src="https://cdn.oncehub.com/mergedjs/so.js"></script>
        )}
      </Helmet>
      <div className={styles.page}>
        <header className={styles.header}>
          <div className={styles.logoContainer}>
            <img src={theme === 'light' ? hookPointLogoBlack : hookPointLogo} alt="" />
          </div>
        </header>
        {downloadFileUrl && (
          <TopBanner
            isVslPage
            onClose={() => setDownloadFileUrl(null)}
            className={styles.topBanner}
          >
            {autoDownload ? (
              <>
                Thank you for your download! <br></br>
                <a href={downloadFileUrl} download>
                  Click here
                </a>{' '}
                if your download doesn't start automatically
              </>
            ) : (
              <>
                Thank you for your interest! <br></br>
                <a href={downloadFileUrl} download>
                  Click here to initiate your download
                </a>
              </>
            )}
          </TopBanner>
        )}
        <div className={clsx(styles.container, downloadFileUrl && styles.withBanner)}>
          <div className={styles.contentContainer}>
            <div className={styles.videoSectionContainer}>
              <VideoSection title={videoSection.title} videoUrl={videoSection.videoUrl} />
            </div>
            <div className={styles.calendarTextContainer} id="schedule-call">
              <div className={styles.textContainer}>
                <h2 className={styles.title}>
                  {replaceNewLinesWithBr(title, str =>
                    wrapCurlyBracedInNoBreak(str, wrapSquareBracketedWithEm),
                  )}
                </h2>
                <p className={styles.subtitle}>{replaceNewLinesWithBr(text)}</p>
              </div>
              <div className={styles.calendarContainer}>
                {scheduleOnceCalendar && (
                  <div
                    id={'SOIDIV_' + scheduleOnceCalendar}
                    data-so-page={scheduleOnceCalendar}
                    data-height="550"
                    data-style="border: 1px solid #D8D8D8; min-width: 290px; max-width: 900px;"
                    data-psz="10"
                  ></div>
                )}
              </div>
            </div>
          </div>
        </div>
        <NumbersListModule {...numbersSection} />
        <CaseStudiesModule
          title={caseStudySection.title}
          caseStudies={caseStudySection.caseStudies}
        ></CaseStudiesModule>
        <CTABannerModule {...ctaSection} to={'#schedule-call'} />
        <div className={styles.footer}>
          <Footer padding="38px 16px" noBackground withPageTheme />
        </div>
      </div>
    </main>
  );
};

export default ScheduleCallPage;
