// extracted by mini-css-extract-plugin
export var contentContainer = "NumbersListModule-module--contentContainer--e67ac";
export var divider = "NumbersListModule-module--divider--0192f";
export var number = "NumbersListModule-module--number--70ed2";
export var numberContainer = "NumbersListModule-module--numberContainer--9171d";
export var numberSubtitle = "NumbersListModule-module--numberSubtitle--93e69";
export var numberSupertitle = "NumbersListModule-module--numberSupertitle--a2340";
export var numbersContainer = "NumbersListModule-module--numbersContainer--1243d";
export var section = "NumbersListModule-module--section--55ffb";
export var text = "NumbersListModule-module--text--b7c0d";
export var textContainer = "NumbersListModule-module--textContainer--fdb1a";
export var textStyleLargeTitle = "NumbersListModule-module--text-style-large-title--17c34";
export var textStyleLargestTitle = "NumbersListModule-module--text-style-largest-title--517ad";
export var textStyleMediumTitle = "NumbersListModule-module--text-style-medium-title--14b5e";
export var textStyleSmallTitle = "NumbersListModule-module--text-style-small-title--97bd5";
export var title = "NumbersListModule-module--title--b2fca";